<template>
  <div id="query-result">
    <a-spin :spinning="loading">
      <a-descriptions :column="2">
        <template #title>
          <p style="font-weight: 500;font-size: 18px;margin-bottom: 0;">miRNA:<b>{{ miRNA }}</b></p>
        </template>

        <a-descriptions-item v-for="item in Object.keys(infoData)" :label="item">
          {{ infoData[item] }}
        </a-descriptions-item>
      </a-descriptions>

      <div class="download">
        <h1>Target Genes</h1>

        <a-tag color="orange" @click="downloadFile(targetCsvUrl)">
          <a-icon type="cloud-download"/> download csv
        </a-tag>
      </div>

      <a-table :columns="targetColumns" :data-source="targetData" :scroll="{x:true}" rowKey="ENSEMBLE"
               :pagination="false" style="margin-bottom: 20px;"/>
      <a-pagination style="padding-top: 0"
          :total="pagination.total"
          :show-total="(total) =>`Total ${total} items`"
          :page-size.sync="pagination.pageSize"
          v-model="pagination.current" showSizeChanger
          @change="getTargetData" @showSizeChange="getTargetData"
      />

      <Chart echarts-id="micRNA-target-genes" :option="chartOption"
             :height="chartHeight" :width="chartWidth"/>

      <div class="download">
        <h1>Function enrichment</h1>

        <a-tag color="orange" @click="downloadFile(enrichmentCsvUrl)">
          <a-icon type="cloud-download"/> download csv
        </a-tag>
      </div>
      <a-table :columns="enrichmentColumns" :data-source="enrichmentData" :scroll="{x:true}" rowKey="term_id"
               :pagination="false"/>
      <a-pagination style="padding-top: 10px"
                    :total="pagination1.total"
                    :show-total="(total) =>`Total ${total} items`"
                    :page-size.sync="pagination1.pageSize"
                    v-model="pagination1.current" showSizeChanger
                    @change="getEnrichmentData" @showSizeChange="getEnrichmentData"
      />

      <iframe :src="enrichmentHtml" frameborder="0" style="border: 0;width: 100%;min-height: 50vh"></iframe>
    </a-spin>
  </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
import MicRNATargetGenes from "@/chartsOptions/micRNATargetGenes";
import {parseCsv, downloadFile} from "@/utils/csv.js";
import {get_target_files} from "@/request/api.js";
import {fetchMiRNAInfo, fetchMiRNATarget} from "@/request/niu_api";
import bus from "@/utils/bus";

let targetCsvData = [];
let enrichmentCsvData = [];

export default {
  name: "LayoutMicRNATargetSearchResult",
  components: {
    Chart,
  },
  data() {
    return {
      loading: true,
      miRNA: "",
      pagination: {
        current:1,
        pageSize: 10,
        total:0,
        showSizeChanger: true,
      },
      pagination1: {
        current:1,
        pageSize: 10,
        total:0,
        showSizeChanger: true,
      },
      infoData: {},
      targetColumns: [],
      targetData: [],
      targetCsvUrl:"",
      enrichmentColumns: [],
      enrichmentData: [],
      enrichmentCsvUrl:"",
      enrichmentHtml: "",
      chartOption: null,
      chartHeight:"45vh",
      chartWidth:"100%"
    };
  },
  async created() {
    this.miRNA = this.$route.query.searchValue;
    this.getInfo()

    get_target_files(this.miRNA).then(res => {
      if (res.status !== 200) {
        this.$message.error(res.err);
        this.loading = false;

        return;
      }

      const dir = `${this.common.source_url}mir2target/${this.miRNA}/`;

      const _this = this;
      const num = new Proxy({value: 0}, {
        set(target, p, newValue) {
          target[p] = newValue;
          if (target.value === 2) {
            _this.loading = false;
          }
        }
      })

      this.enrichmentHtml = `${dir}${res.function_html}`;

      this.targetCsvUrl = `${dir}${res.target_genes}`;
      parseCsv(this.targetCsvUrl, (csv) => {
        targetCsvData = csv;
        this.pagination.total = csv.length;

        this.getTargetData();

        this.targetColumns = [
          {
            title: "Gene_Symbol",
            dataIndex: "Gene_Symbol",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          },
          {
            title: "ENSEMBLE",
            dataIndex: "ENSEMBLE",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          },
          {
            title: "description",
            dataIndex: "description",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          }
        ];

        num.value++;
      })

      this.enrichmentCsvUrl = `${dir}${res.function_csv}`;
      parseCsv(this.enrichmentCsvUrl, (csv) => {
        enrichmentCsvData = csv;
        this.pagination1.total = csv.length;

        this.getEnrichmentData();

        this.enrichmentColumns = [
          {
            title: "term_name",
            dataIndex: "term_name",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          },
          {
            title: "term_id",
            dataIndex: "term_id",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          },
          {
            title: "source",
            dataIndex: "source",
            customRender: (text) => {
              if (text === "NA" || !text) {
                return ""
              }
              return text
            }
          },
          {
            title: "p_value",
            dataIndex: "p_value",
            customRender: (text, row, index) => text.toFixed(3)
          }
        ];

        num.value++;
      })
    })

    this.generateChart();
  },
  methods: {
    downloadFile,
    async getInfo(){
      const res = await fetchMiRNAInfo({
        search: this.miRNA,
        "page": 1,
        "limit": 10,
        "sort": "mirna_id",
        "order": "asc"
      })
      this.infoData = res.data.data[0];
    },
    generateChart() {
      fetchMiRNATarget({
        "mirna_arr": [this.miRNA]
      }).then(res => {
        MicRNATargetGenes.series[0].categories = [
          {
            name: this.miRNA,
            itemStyle: {
              color: "#FF9900"
            }
          },
          {
            name: "target",
          }
        ];
        MicRNATargetGenes.series[0].data = res.data.map(item => {
          return {
            name: item.target,
            category: "target"
          }
        });
        MicRNATargetGenes.series[0].data.unshift({
          name: this.miRNA,
          category: this.miRNA
        });
        // 因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画，在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。
        MicRNATargetGenes.series[0].force.layoutAnimation = false;

        MicRNATargetGenes.series[0].links = res.data.map(item => {
          return {
            source: this.miRNA,
            target: item.target,
          }
        });

        MicRNATargetGenes.toolbox.feature = {
          // restore: {
          //   icon:"image:///images/echarts-toolbox-refresh.png"
          // },
          // saveAsImage:{
          //   show:true,
          //   title:'保存为图片',
          //   icon:'image:///images/echarts-toolbox-download.png',
          //   type:'png',
          //   pixelRatio:1,
          //   backgroundColor:'#FFFFFF',
          //   connectedBackgroundColor:'red',
          //   excludeComponents:['toolbox'],
          // }

          restore: {
            icon:"image:///images/echarts-toolbox-refresh.png"
          },
          myDownload: {
            show: true,
            title: "下载",
            icon: 'image:///images/echarts-toolbox-download.png',
            onclick: () => {
              this.echartsExportImg();
            }
          }
        }

        this.chartOption = MicRNATargetGenes;
      })
    },
    echartsExportImg(){
      bus.$emit("globalLoading", {bool: true, tip: "正在下载中..."});

      const convertBase64UrlToBlob = (base64) => {
        const parts = base64.dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i++) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
      };

      const myChart = this.$echarts.getInstanceByDom(
          document.getElementById("micRNA-target-genes")
      );
      this.chartHeight = "450vh";
      this.chartWidth = "450vw";
      myChart.resize();

      myChart.setOption({
        series: [
          {
            zoom:5
          }
        ]
      })

      setTimeout(() => {
        //下载结束时执行
        const close = () => {
          this.chartHeight = "45vh";
          this.chartWidth = "100%";

          myChart.setOption({
            series: [
              {
                zoom:1
              }
            ]
          })
          myChart.resize();

          setTimeout(() => {
            myChart.resize();
            bus.$emit("globalLoading", {bool: false, tip: "正在下载中..."})
          }, 1000)
        }

        myChart.resize();

        const url = myChart.getConnectedDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff", // 图表背景色
          excludeComponents: [// 保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
          ],
          type: "png", // 图片类型支持png和jpeg
        });
        const a = document.createElement('a');
        a.download = `MicRNA-target-genes.png`;
        a.target = '_blank';
        a.href = url;
        // Chrome and Firefox
        if (typeof MouseEvent === 'function') {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          a.dispatchEvent(evt);
          close();
        } else {
          // IE
          const base64 = {
            dataURL: url,
            type: 'png',
            ext: 'png',
          };
          const blob = convertBase64UrlToBlob(base64);
          // 下载
          window.navigator.msSaveBlob(
              blob,
              `MicRNA-target-genes.png`
          );
          close();
        }
      }, 1000)
    },
    getText(text) {
      if (text === "NA" || !text) {
        return ""
      }
      return text
    },
    getTargetData(){
      this.targetData = targetCsvData.slice((this.pagination.current - 1) * this.pagination.pageSize,this.pagination.current * this.pagination.pageSize)
    },
    getEnrichmentData(){
      this.enrichmentData = enrichmentCsvData.slice((this.pagination1.current - 1) * this.pagination1.pageSize,this.pagination1.current * this.pagination1.pageSize)
    },
  },
};
</script>

<style scoped lang="scss">
#query-result {
  background: #F9F9FB;
  padding: 1.4rem 8rem;
  position: relative;

  ::v-deep .ant-spin-container {
    padding: 2rem;
    background: #fff;
  }

  ::v-deep .ant-descriptions-item{
    padding-left: 3rem;

    .ant-descriptions-item-label{
      font-size: 16px;
      font-weight: 500;
    }

    .ant-descriptions-item-content{
      font-size: 16px;
    }
  }

  .download {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .ant-tag {
      cursor: pointer;
      height: 33px;
      line-height: 33px;
      font-size: 16px;
    }
  }
}
</style>
